import React from "react"
import Layout from "../../../components/sermonLayoutSurprise"
import SEO from "../../../components/seo"

const SermonPost = () => (
  <Layout sermonSrc="https://www.youtube.com/embed/5IPFnCp4Z_g">
    <SEO title="Sound Doctrine - Imperatives" />
  </Layout>
)

export default SermonPost
